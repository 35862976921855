import './App.css';
import React from 'react';
import ChatBox from './ChatBox';

function App() {
  return (
    <div className="App flex flex-col">
      <ChatBox />
    </div>
  );
}

export default App;
