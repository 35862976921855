import axios from "axios";

// const SYSTEM_PROMPT = `Bạn tên là Phúc, bạn sẽ cùng tạo câu chuyện cổ tích với người dùng
// Bạn hãy thu thập các thông tin như là nhân vật, tên nhân vật, ngữ cảnh và các gợi ý khác.
// Khi có đủ thông tin thì bạn hãy tạo câu chuyện.
// Đừng tự đặt tên nhân vật, hãy hỏi người dùng về tên của nhân vật.
// Hãy hỏi từng câu, tránh người dùng bị choáng ngộp!
// Khi bắt đầu câu chuyện, bạn phải thêm câu sau: Câu chuyện của bạn đây:\n

// `
const CHILD = ' Hãy thử trò chuyện và phản hồi với người dùng.'

export const getChatResponse = async (prompt, messages) => {
    const response = await axios.post("https://api.openai.com/v1/chat/completions", {
        "model": "gpt-4",
        "messages": [
            {
                "role": "system",
                "content": CHILD
            },
            ...messages,
            { "role": "user", "content": prompt }
        ],
        "tools": [
            {
                "type": "function",
                "function": {
                    "name": "get_current_weather",
                    "description": "Get the current weather",
                    "parameters": {
                        "type": "object",
                        "properties": {
                            "location": {
                                "type": "string",
                                "description": "The city and state, e.g. San Francisco, CA",
                            },
                            "format": {
                                "type": "string",
                                "enum": ["celsius", "fahrenheit"],
                                "description": "The temperature unit to use. Infer this from the users location.",
                            },
                        },
                        "required": ["location", "format"],
                    },
                }
            },
            {
                "type": "function",
                "function": {
                    "name": "get_music_name",
                    "description": "Get the music name",
                    "parameters": {
                        "type": "object",
                        "properties": {
                            "name": {
                                "type": "string",
                                "description": "name music eg: Happy birthday",
                            },
                        },
                        "required": ["name"],
                    },
                }
            },
            {
                "type": "function",
                "function": {
                    "name": "create_img",
                    "description": "Create image",
                    "parameters": {
                        "type": "object",
                        "properties": {
                            "prompt": {
                                "type": "string",
                                "description": "Summarize the user's conversation or wishes to create a photo eg: Cartoon",
                            },
                        },
                        "required": ["prompt"],
                    },
                }
            },
            {
                "type": "function",
                "function": {
                    "name": "get_n_day_weather_forecast",
                    "description": "Get an N-day weather forecast",
                    "parameters": {
                        "type": "object",
                        "properties": {
                            "location": {
                                "type": "string",
                                "description": "The city and state, e.g. San Francisco, CA",
                            },
                            "format": {
                                "type": "string",
                                "enum": ["celsius", "fahrenheit"],
                                "description": "The temperature unit to use. Infer this from the users location.",
                            },
                            "num_days": {
                                "type": "integer",
                                "description": "The number of days to forecast",
                            }
                        },
                        "required": ["location", "format", "num_days"]
                    },
                }
            },
        ],
        "temperature": 0.7
    },
        {
            headers: {
                Authorization: 'Bearer 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'
            }
        })
    console.log(response.data);
    const assistantMessage = response.data.choices[0].message.content;

    const tool_calls = response.data.choices[0].message.tool_calls;


    if (tool_calls) {
        // API
        const name = tool_calls[0].function.name;
        const argsStr = tool_calls[0].function.arguments;
        const args = JSON.parse(argsStr);
        if (name === 'create_img') {
            const prompt = args.prompt;
            return await getCreateImage(prompt)
        }
    }

    return assistantMessage;
}
export const getCreateImage = async (prompt) => {
    const response = await axios.post("https://api.openai.com/v1/images/generations", {
        "model": "dall-e-3",
        "prompt": prompt,
        "n": 1,
        "size": "1024x1024"

    },
        {
            headers: {
                Authorization: 'Bearer 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'
            }
        }
    );

    const imageURL = response.data.data[0].url
    return imageURL
}

