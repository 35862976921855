import React, { useEffect, useRef, useState } from 'react';
import { getChatResponse } from './api';
import { TypeAnimation } from 'react-type-animation';


const ChatBox = () => {
    const [userInput, setUserInput] = useState("")
    const [messages, setMessages] = useState(JSON.parse(localStorage.getItem("messages")) || []);
    const [isLoading, setLoading] = useState(false)
    const [isTyping, setIsTyping] = useState(false);
    const ref = useRef()

    const onSubmit = async (e) => {
        e.preventDefault();
        if (isLoading) {
            return
        }
        setLoading(true)
        const userMessage = {
            role: "user",
            content: userInput
        }
        setMessages(prevMessages => [...prevMessages, userMessage])
        setUserInput("");

        const response = await getChatResponse(userInput, messages.filter(item => !!item.content))
        const assistantMessage = {
            role: "assistant",
            content: response
        }
        setLoading(false)
        setIsTyping(true);
        setMessages(prevMessages => [...prevMessages, assistantMessage])
    }

    useEffect(() => {
        localStorage.setItem("messages", JSON.stringify(messages))
        ref.current?.scrollTo({
            behavior: "smooth",
            top: ref.current?.scrollHeight
        })
    }, [messages])

    const clearMessage = () => {
        setMessages([])
    }


    return (
        <div className="bg-gray-100 w-full h-full flex flex-col relative">
            <div className='absolute top-4 right-4'>
                <button onClick={clearMessage}>Xóa tin nhắn</button>
            </div>
            <div ref={ref} className="w-full border-gray-300 rounded-lg shadow-xs flex flex-col flex-1 overflow-y-auto p-4">
                <div className="flex mb-4">
                    <div className="bg-gray-200 max-w-[80%] text-start text-gray-800 py-2 px-4 rounded-lg whitespace-break-spaces ">
                        Xin chào. Tôi là chatbot. Bạn hãy nhập thông tin vào thanh công cụ bên dưới.
                    </div>
                </div>
                {messages.map((message, index) => {
                    if (message.role === 'user') {
                        return <div key={index} className="flex justify-end mb-4">
                            <div className="bg-blue-500  max-w-[80%]  text-white py-2 px-4 rounded-lg">
                                {message.content}
                            </div>
                        </div>
                    }
                    if (message.content && message.content.startsWith('http')) {
                        return <div key={index} className="flex justify-start mb-4">
                            <img alt="ai" src={message.content} className='w-[80%] max-w-[512px] aspect-square' />
                        </div>
                    }
                    return <div key={index} className="flex justify-start mb-4">
                        <TypeAnimation
                            sequence={[
                                message.content,
                                () => setIsTyping(false)
                            ]}
                            preRenderFirstString={!isTyping || index < messages.length - 1}
                            cursor={isTyping && index === messages.length - 1}
                            wrapper="div"
                            className='bg-gray-200 text-base self-start  max-w-[80%] text-start text-gray-800 py-2 px-4 rounded-lg whitespace-break-spaces'
                            speed={50}
                        />
                    </div>
                    // return <div key={index} className="flex mb-4">
                    //     <div className="bg-gray-200  max-w-[80%] text-start text-gray-800 py-2 px-4 rounded-lg whitespace-break-spaces ">
                    //         {message.content}
                    //     </div>
                    // </div>
                })}
                {isLoading && <div className="flex mb-4">
                    <div className="bg-gray-200 text-start text-gray-800 py-2 px-4 rounded-lg whitespace-break-spaces ">
                        ...
                    </div>
                </div>}
            </div>
            <form onSubmit={onSubmit} className="p-4 flex">
                <input value={userInput} onChange={e => setUserInput(e.target.value)} type="text" className="w-full rounded-lg border-gray-300 border text-black p-2" placeholder="Nhập tin nhắn của bạn..." />
                <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-lg ml-2">Gửi</button>
            </form>
        </div>
    );
};

export default ChatBox;
